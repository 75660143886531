<template>
  <v-row>
    <!-- Foto -->
    <v-col cols="12">
      <h3 class="mb-2">Foto</h3>
      <div class="d-flex flex-row align-center">
        <v-avatar class="elevation-3" size="110" rounded="5">
          <v-img :src="previewFoto"></v-img>
        </v-avatar>
        <v-file-input
          class="ml-2"
          accept="image/png, image/jpeg, image/bmp"
          placeholder="Coloque sua foto"
          prepend-icon="mdi-camera"
          hide-details
          outlined
          dense
          v-model="proccessInputPhoto"
          color="primary"
        >
        </v-file-input>
      </div>
    </v-col>
    <!-- Nome -->
    <v-col cols="12" sm="6">
      <v-text-field
        label="Nome"
        placeholder="Digite seu nome."
        outlined
        dense
        v-model="pessoais.nome"
        data-vv-as="curso"
        persistent-hint
        :hint="errors.first('res-candidato-pessoais.nome')"
        :error="
          errors.collect('res-candidato-pessoais.nome').length ? true : false
        "
        :hide-details="
          errors.collect('res-candidato-pessoais.nome').length ? false : true
        "
        v-validate="'required'"
        data-vv-validate-on="change|blur|input"
        data-vv-scope="res-candidato-pessoais"
        ref="nome"
        id="nome"
        name="nome"
      ></v-text-field>
    </v-col>
    <!-- Contato -->
    <v-col cols="12" sm="6">
      <v-text-field
        label="Contato"
        ref="contato"
        placeholder="Digite seu contato."
        outlined
        dense
        v-model="pessoais.contato"
        data-vv-as="contato"
        persistent-hint
        :hint="errors.first('res-candidato-pessoais.contato')"
        :error="
          errors.collect('res-candidato-pessoais.contato').length ? true : false
        "
        :hide-details="
          errors.collect('res-candidato-pessoais.contato').length ? false : true
        "
        v-validate="'required'"
        data-vv-validate-on="change|blur|input"
        data-vv-scope="res-candidato-pessoais"
        id="contato"
        name="contato"
        v-mask="'(##) #####-####'"
      ></v-text-field>
    </v-col>
    <!-- Aniversario -->
    <v-col cols="12" sm="6">
      <v-menu
        ref="menuAniversario"
        v-model="menuAniversario"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="inputAniversario"
            label="Aniversário"
            persistent-hint
            prepend-icon="mdi-calendar"
            outlined
            dense
            v-bind="attrs"
            v-on="on"
            data-vv-as="aniversário"
            :hint="errors.first('res-candidato-pessoais.aniversario')"
            :error="
              errors.collect('res-candidato-pessoais.aniversario').length
                ? true
                : false
            "
            :hide-details="
              errors.collect('res-candidato-pessoais.aniversario').length
                ? false
                : true
            "
            v-validate="'required'"
            data-vv-validate-on="change|blur|input"
            data-vv-scope="res-candidato-pessoais"
            id="aniversario"
            name="aniversario"
            clearable
            @click:clear="pessoais.aniversario = null"
            autocomplete="off"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="pessoais.aniversario"
          no-title
          @input="menuAniversario = false"
          locale="pt-br"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <!-- Sexo -->
    <v-col cols="12" sm="6">
      <v-select
        :items="sexos"
        v-model="pessoais.sexo"
        item-text="text"
        item-value="value"
        label="Gênero"
        placeholder="Selecione uma gênero"
        outlined
        dense
        data-vv-as="gênero"
        persistent-hint
        :hint="errors.first('res-candidato-pessoais.sexo')"
        :error="
          errors.collect('res-candidato-pessoais.sexo').length ? true : false
        "
        :hide-details="
          errors.collect('res-candidato-pessoais.sexo').length ? false : true
        "
        v-validate="'required'"
        data-vv-validate-on="change|blur|input"
        data-vv-scope="res-candidato-pessoais"
        id="sexo"
        name="sexo"
      ></v-select>
    </v-col>
    <!-- cpf -->
    <v-col cols="12" sm="6">
      <v-text-field
        label="CPF"
        ref="cpf"
        placeholder="Digite seu cpf."
        outlined
        dense
        hide-details
        v-model="pessoais.cpf"
        v-mask="'###.###.###-##'"
      ></v-text-field>
    </v-col>
    <!-- Estado civil -->
    <v-col cols="12" sm="6">
      <v-select
        :loading="loadings.loadingEstadoCivil"
        :items="estadosCivies"
        v-model="pessoais.id_estado_civil"
        item-text="descricao"
        item-value="id"
        label="Estado civil"
        placeholder="Selecione seu estado civil"
        outlined
        dense
        data-vv-as="estado civil"
        persistent-hint
        :hint="errors.first('res-candidato-pessoais.id_estado_civil')"
        :error="
          errors.collect('res-candidato-pessoais.id_estado_civil').length
            ? true
            : false
        "
        :hide-details="
          errors.collect('res-candidato-pessoais.id_estado_civil').length
            ? false
            : true
        "
        v-validate="'required'"
        data-vv-validate-on="change|blur|input"
        data-vv-scope="res-candidato-pessoais"
        id="id_estado_civil"
        name="id_estado_civil"
      ></v-select>
    </v-col>
    <!-- Possui filho -->
    <v-col cols="12" sm="6">
      <v-checkbox
        v-model="pessoais.filho"
        true-value="1"
        false-value="0"
        hide-details
        :label="`Possui filhos? ${pessoais.filho == '0' ? 'Não' : 'Sim'}`"
      ></v-checkbox>
    </v-col>
    <!-- Quantidade filhos -->
    <v-col cols="12" sm="6">
      <v-text-field
        :disabled="pessoais.filho == '0' ? true : false"
        v-model="pessoais.quantidade_filho"
        label="Quantidade de filhos"
        type="number"
        outlined
        dense
        hide-details
      ></v-text-field>
    </v-col>
    <!-- Deficiência -->
    <v-col cols="12" sm="6">
      <v-checkbox
        v-model="pessoais.deficiente"
        true-value="1"
        false-value="0"
        hide-details
        :label="`Possui deficiência? ${
          pessoais.deficiente == '0' ? 'Não' : 'Sim'
        }`"
      ></v-checkbox>
    </v-col>
    <!-- Deficiência -->
    <v-col cols="12" sm="6">
      <v-select
        :disabled="pessoais.deficiente == '0' ? true : false"
        :items="deficiencias"
        v-model="pessoais.id_deficiencia"
        :loading="loadings.loadingDeficiente"
        item-text="descricao"
        item-value="id"
        label="Deficiência"
        placeholder="Selecione uma deficiência"
        outlined
        dense
      ></v-select>
    </v-col>
    <!-- Grau de deficiência -->
    <v-col cols="12" sm="6">
      <v-text-field
        :disabled="pessoais.deficiente == '0' ? true : false"
        v-model="pessoais.grau_deficiente"
        label="Grau de deficiência"
        type="number"
        outlined
        dense
        hide-details
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <div class="d-flex justify-end">
        <v-btn
          :loading="loadings.loadingButtonSave"
          color="primary"
          @click="save()"
        >
          Salvar
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { unMaskCpf, unMaskPhone } from "@/helpers";
import userNotImage from "@/assets/images/avatars/user_not_image.png";

export default {
  name: "InformacoesPessoaisEdit",
  inject: ["$validator"],
  data() {
    return {
      menuAniversario: false,
      loadings: {
        loadingDeficiente: true,
        loadingEstadoCivil: true,
        loadingButtonSave: false,
      },
      sexos: [
        {
          text: "Masculino",
          value: "M",
        },
        {
          text: "Feminino",
          value: "F",
        },
        {
          text: "Lgbtqia+",
          value: "L",
        },
        {
          text: "Prefiro não dizer",
          value: "N",
        },
      ],

      item: {},
    };
  },
  async created() {
    await this.$store.dispatch("resEstadosCivies/initialize");
    this.loadings.loadingEstadoCivil = false;

    await this.$store.dispatch("resDeficiencias/initialize");
    this.loadings.loadingDeficiente = false;
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    save() {
      this.$validator.validate("res-candidato-pessoais.*").then((result) => {
        if (result) {
          this.loadings.loadingButtonSave = true;

          Object.assign(this.item, this.pessoais);

          this.item.cpf = unMaskCpf(this.item.cpf);
          this.item.contato = unMaskPhone(this.item.contato);

          this.tratativeFilhos().tratativeDeficiencia();

          let formData = new FormData();
          formData.append("nome", this.item.nome);
          formData.append("contato", this.item.contato);
          formData.append("aniversario", this.item.aniversario);
          formData.append("sexo", this.item.sexo);
          formData.append("cpf", this.item.cpf);
          formData.append("id_estado_civil", this.item.id_estado_civil);
          formData.append("filho", this.item.filho);
          formData.append("quantidade_filho", this.item.quantidade_filho);
          formData.append("deficiente", this.item.deficiente);
          formData.append("id_deficiencia", this.item.id_deficiencia);
          formData.append("grau_deficiente", this.item.grau_deficiente);

          if (
            this.item.foto != null &&
            typeof this.item.foto == "object" &&
            this.item.foto.name
          ) {
            formData.append("foto", this.item.foto);

            this.changePhotoCandidato();
          }

          let data = {
            uuid: this.candidato.uuid,
            data: formData,
          };

          this.$store
            .dispatch("resCandidatos/salvarDadosPessoaisCandidato", data)
            .then((response) => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                cor: "green",
                mensagem: "Informações pessoais atualizadas com sucesso.",
                duracao: 3000,
              });

              this.changeDataCandidato();
            })
            .catch(() => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                cor: "red",
                mensagem: "Ocorreu um erro ao atualizar informações pessoais.",
                duracao: 3000,
              });
            })
            .finally(() => {
              this.loadings.loadingButtonSave = false;
              this.item = {};
            });
        }
      });
    },
    tratativeFilhos() {
      if (this.item.filho == 0) {
        this.item.quantidade_filho = 0;
      }

      return this;
    },
    tratativeDeficiencia() {
      if (this.item.deficiente == 0) {
        this.item.grau_deficiente = 0;
        this.item.id_deficiencia = null;
      }

      return this;
    },

    changePhotoCandidato() {
      const newImage = URL.createObjectURL(this.pessoais.foto);
      const user = JSON.parse(localStorage.getItem("userData"));

      this.candidato.avatar = newImage;
      user.avatar = newImage;

      localStorage.setItem("userData", JSON.stringify(user));

      return this;
    },

    changeDataCandidato() {
      this.candidato.contato = this.item.contato;
      this.candidato.nome = this.item.nome;

      return this;
    },
  },
  computed: {
    pessoais: {
      get() {
        return this.$store.getters["resCandidatos/getPessoais"];
      },
      set(value) {
        this.$store.dispatch("resCandidatos/setPessoais", value);
      },
    },
    deficiencias: {
      get() {
        return this.$store.getters["resDeficiencias/getDeficiencias"];
      },
    },
    estadosCivies: {
      get() {
        return this.$store.getters["resEstadosCivies/getEstadosCivies"];
      },
    },
    candidato: {
      get() {
        return this.$store.getters["resCandidatos/getCandidato"];
      },
      set(value) {
        this.$store.dispatch("resCandidatos/setCandidato", value);
      },
    },

    /**
     * @description Renderiza um preview da imagem
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    previewFoto() {
      if (this.pessoais && this.pessoais.foto == null) return userNotImage;

      if (
        this.pessoais.foto &&
        typeof this.pessoais.foto == "object" &&
        this.pessoais.foto.name
      ) {
        return URL.createObjectURL(this.pessoais.foto);
      }

      return this.pessoais.avatar;
    },

    /**
     * @description Processa o valor do input da foto
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    proccessInputPhoto: {
      get() {
        if (this.pessoais && typeof this.pessoais.foto == "string") return null;

        return this.pessoais.foto;
      },
      set(value) {
        this.pessoais.foto = value;
      },
    },

    inputAniversario: {
      get() {
        return this.formatDate(this.pessoais.aniversario);
      },
      set(value) {},
    },
  },
  watch: {
    "pessoais.deficiente"(newValue, oldValue) {
      if (newValue == "0") {
        this.pessoais.id_deficiencia = null;
        this.pessoais.grau_deficiente = null;
      }
    },
    "pessoais.filho"(newValue, oldValue) {
      if (newValue == "0") {
        this.pessoais.quantidade_filho = null;
      }
    },
  },
};
</script>
